/*All Desktop*/
@media only screen and (min-width:1280px){
    .loader-page {
      position: fixed;
      z-index: 25000;
      background: #1d1d1b81;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all .3s ease;
      overflow-x:hidden;
    }
    .loader-page::before {
      content: "";
      position: absolute;
      border: 2px solid #FFFFFF;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s linear infinite;
      transform: rotate(0deg);
    }
    @keyframes rotarload {
        0%   {transform: rotate(0deg)}
        100% {transform: rotate(360deg)}
    }
    .loader-page::after {
      content: "";
      position: absolute;
      border: 2px solid #4d2fa8;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s ease-out infinite;
      transform: rotate(0deg);
    }
    #img-loader
    {
      width: 150px;
      height: 200px;
    }

    .loader-hide
    {
      display: none;
      opacity: 0;
    }
  }

  /*Great Mobile*/
  @media only screen and (min-width:720px) and (max-width:1219px){
    #app_NathansCorner
    {
      visibility: hidden;
    }

    .loader-page {
      position: fixed;
      z-index: 25000;
      background: #1d1d1b81;
      left: 10px;
      top: -105px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all .3s ease;
    }
    .loader-page::before {
      content: "";
      position: absolute;
      border: 2px solid #443a90;
      width: 350px;
      height: 350px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s linear infinite;
      transform: rotate(0deg);
    }
    @keyframes rotarload {
        0%   {transform: rotate(0deg)}
        100% {transform: rotate(360deg)}
    }
    .loader-page::after {
      content: "";
      position: absolute;
      border: 2px solid #322a6a;
      width: 350px;
      height: 350px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s ease-out infinite;
      transform: rotate(0deg);
    }
    #img-loader
    {
      width: 250px;
      height: 350px;
    }
    .loader-hide
    {
      display: none;
      opacity: 0;
    }
  }

  /*Small Mobile Loader*/
  @media only screen and (min-width:300px) and (max-width:719px){

    #app_NathansCorner
    {
      visibility: hidden;
    }

    .loader-page {
      position: fixed;
      z-index: 25000;
      background: #1d1d1b81;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all .3s ease;
    }
    .loader-page::before {
      content: "";
      position: absolute;
      border: 2px solid #f7f7f7;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s linear infinite;
      transform: rotate(0deg);
    }
    @keyframes rotarload {
        0%   {transform: rotate(0deg)}
        100% {transform: rotate(360deg)}
    }
    .loader-page::after {
      content: "";
      position: absolute;
      border: 2px solid #4d2fa8;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-sizing: border-box;
      border-left: 2px solid #4d2fa800;
      border-top: 2px solid #4d2fa800;
      animation: rotarload 1s ease-out infinite;
      transform: rotate(0deg);
    }
    #img-loader
    {
      width: 150px;
      height: 200px;
    }
    .loader-hide
    {
      display: none;
      opacity: 0;
    }
  }
