html {
  font-family: 'Handjet', cursive;
  font-family: 'Ysabeau SC', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Handjet', cursive;
  font-family: 'Ysabeau SC', sans-serif;
  color: white;
  cursor:url(../src/assets/special/cursor.cur),auto;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#general{
  font-family: 'Handjet', cursive;
  font-family: 'Ysabeau SC', sans-serif;
}
