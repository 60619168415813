.banner {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    background-color: #343a40; /* Dark background color */
    z-index: 1000; /* Ensure it's above other content */
}

@media only screen and (min-width: 600px) {

    .img_banner{
        width: 4%;
        /* height: auto; */
    }

  }

/* @media only screen and (max-width: 600px) { */
@media only screen and (max-width: 600px) {

    .img_banner{
        width: 11%;
    }
}

