.cardColor {
    background-color: rgb(33, 37, 41);
    color: white;
    width: '100%'; 
    margin: 'auto';
}

.image-container {
    width: 100px; /* Set the desired width */
    height: 100px; /* Set the desired height */
    overflow: hidden; /* Hide the part of the image that overflows */
}

.image-container img {
    width: 100%; /* Make the image fill the container */
    height: 100%; /* Ensure the image fills the container height */
    object-fit: cover; /* Maintain aspect ratio while covering the container */
    object-position: center; /* Center the image inside the container */
}

#get-started-page {
    display: flex;
    justify-content: center;
    align-items: center;
     /* Make the container take full height */
}

.section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%; /* Container's width is 100% of parent */
    max-width: 500px; /* Optional max width to limit size */
    text-align: center;
}

.image-container img {
    width: 100%;
    max-width: 200px; /* Limit max size */
    height: auto;
    display: block;
    margin: 0 auto;
}

.associated p {
    font-size: 1.2em;
    margin-top: 10px;
}