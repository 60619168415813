:root{
    --padding_top_bottom: 30px;
}

.biz_layout{
  background-color: #333;
}

/* ------------------------------------------------------- */

/* Define animation classes */


@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .business-visible {
    opacity: 1;
    animation: fadeIn 0.5s ease;
  }
  
  .non-business-visible {
    /* opacity: 0;
    animation: fadeOut 0.5s ease; */
    opacity: 1;
    animation: fadeIn 0.5s ease;
  }
  


/* ------------------------------------------------------------- */


.secondThird_container{

    position: relative;
    bottom: 100px;
    color: white;
    animation-name: secondThird;
    animation-duration: 2s;
    bottom: 0px;

  }

  @keyframes secondThird {

    0%   {bottom: 100px; opacity: 0;}
    100%  {bottom: 0px; opacity: 1;}
    
  }

  .secondThird_container_BV{

    position: relative;
    bottom: 0px;
    color: white;
    animation-name: secondThird_BV;
    animation-duration: 2s;
    bottom: 0px;

  }

  @keyframes secondThird_BV {

    0%   {bottom: 0px; opacity: 1;}
    100%  {bottom: -100px; opacity: 0;}
    
  }



/* Add your other styles here */



.content-wrapper {
    padding-top: 70px; /* Adjust as needed to accommodate the height of the banner */
    overflow-y: auto; /* Make the content scrollable */
    height: 100vh; /* Take up the full viewport height */
    box-sizing: border-box;
}

#welcome_title {
    color: white;
    text-align: center;
    margin-top: 20px;
}

/* .main_blurr_in{
  -webkit-filter: blur(0px);
  animation: blurr_in;
  animation-duration: 1s;
} */

.main_blurr_out{
  -webkit-filter: blur(100px);
  animation: blurr_out;
  animation-duration: 1s;
}

/* @keyframes blurr_in {
  0% { -webkit-filter: blur(0px);}
  100% { -webkit-filter: blur(100px);}
  
} */

@keyframes blurr_out {
  0% { -webkit-filter: blur(100px);}
  100% { -webkit-filter: blur(0px);}
}

@media (min-width: 600px) {
    .main_view {
        background-image: url("../../assets/img/JOHN\ TOWNER_Unsplash.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation-delay: 0.5s;
        animation: blurr;
        animation-duration: 1s;
        

    }

    .biz_view {
        background-image: url("../../assets/img/Business/Nathan-Business-bg-desktop.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(50%);
        /* -webkit-filter: blur(0px); */
        /* animation-delay: 1s; */
        animation-delay: 0.5s;
        animation: blurr;
        animation-duration: 1s;
        

        
    }

    @keyframes blurr {
      0% { -webkit-filter: blur(0px);}
      50% { -webkit-filter: blur(20px);}
      100% { -webkit-filter: blur(0px);}
      
    }


    @keyframes title_slide {

        0%   {right: 1000px;}
        50%  {right: -200px;}
        51% { transform: translate(1px, 1px) rotate(0deg); }
        60% { transform: translate(-1px, -2px) rotate(-2deg); }
        65% { transform: translate(-3px, 0px) rotate(4deg); }
        75% { transform: translate(0px, 0px) rotate(0deg); }
        100%  {right: 0px;}
        
      }
}

@media (max-width: 600px) {
    .main_view {
        background-image: url("../../assets/img/JOHN\ TOWNER_Unsplash.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation-delay: 0.5s;
        animation: blurr;
        animation-duration: 1s;
    }
    .biz_view {
        background-image: url("../../assets/img/Business/Nathan-Business-bg-mobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(50%);
        animation-delay: 0.5s;
        animation: blurr;
        animation-duration: 1s;
        
    }

    @keyframes blurr {
      0% { -webkit-filter: blur(0px);}
      50% { -webkit-filter: blur(20px);}
      100% { -webkit-filter: blur(0px);}
      
    }
    @keyframes title_slide {

        0%   {right: 1000px;}
        50%  {right: -15%;}
        51% { transform: translate(1px, 1px) rotate(0deg); }
        60% { transform: translate(-1px, -2px) rotate(-2deg); }
        65% { transform: translate(-3px, 0px) rotate(4deg); }
        75% { transform: translate(0px, 0px) rotate(0deg); }
        100%  {right: 0px;}
        
      }
}



.calendlySpecs{
    height: 500px;
    min-width:320px;
}

.products-carousel{
    
    width: 75%;
    margin-left: 10%;
    padding-bottom: 10%;
    
}

#main_view {
  
    width: 100%;
    height: 100%;
    position: fixed;
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


html{
    /* background-position-y: -300px; */
    font-family: 'Handjet', cursive;
    font-family: 'Ysabeau SC', sans-serif;
    color: white;
    cursor:url(../../assets/special/cursor.cur),auto;
    background-attachment: fixed;

}

body{

    background: transparent;
    width: 100%;
    margin: auto;
    color: white;
    /* text-decoration: underline; */

    font-family: 'Handjet', cursive;
    font-family: 'Ysabeau SC', sans-serif;

}

#welcome_title{

    font-size: 46px;
    position: relative;
    right: 1000px;
    color: "white";

    
    text-align: center;
    padding-top: 40px;
    width: 75%;
    margin-left: 0%;
    /* margin-right: 0%; */
    margin: auto;
    /* padding-bottom: 20px; */

    /* font-family: 'Borel', cursive; */
    font-family: "Chakra Petch", sans-serif;
    animation-name: title_slide;
    animation-duration: 2s;
    right: 0px;

    /* animation-iteration-count: infinite; */

}

/* #welcome_title {
    font-size: 46px;
    position: relative;
    color: white;
    text-align: center;
    padding-top: 40px;
    width: 75%;
    margin: auto;
    font-family: 'Borel', cursive;
  }
  
  #welcome_title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, white 10%, transparent 50%); 
    animation: revealText 2s ease forwards; 
  }
  
  
  @keyframes revealText {
    0% {
      transform: translateX(-100%); 
    }
    100% {
      transform: translateX(0%); 
    }
  } */
  

.first_third{

    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50%;

}

.quote_container{

    display: flex;
    flex-direction: column;
    position: relative;
    top: 100px;

    animation-name: firstThird;
    animation-duration: 2s;
    top: 0px;

}

@keyframes firstThird {

    0%   {top: 100px; opacity: 0;}
    100%  {top: 0px; opacity: 1;}
    
  }


.fx_i1{

    padding-top: var(--padding_top_bottom);
    padding-bottom: var(--padding_top_bottom);
    margin-left: auto;
    margin-right: auto;
    font-size: large;
    color:white;

}

.icon_containers{

    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: center;
}



.prof_pic{

    position: relative;
    top: 30px;
    padding:10px;
    width: 230px;
    height: auto;
    border-radius: 25px;
    border-style:double;
    border-color: white;
    box-shadow: 10px 10px 10px rgb(67, 22, 43);

}

.inner_quote_1{

    position: relative;
    text-align: center;
    top: 10px;


}

.inner_quote{

    position: relative;
    top: 10px;
    /* padding-top: 30px; */

}

.quote_container:hover .prof_pic{

    transition: 0.5s;
    box-shadow: 10px 10px 10px rgb(115, 105, 131);

}

.inner_quote{
    font-size: x-large;
    text-align: center;
}
/* .quote_container:hover .inner_quote{

    transition: 0.5s;
    

} */
.front_padding{

    margin-left: auto;
    margin-right: auto;
    width: 500px;
    max-height: 30%;
    /* border-bottom: solid; */

}

#notion_chart{
    min-height: 220px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 10px;

}

.s1{

    width: 50%;
    margin: auto;
    animation-delay: 1s;
    animation-name: social-rot;
    animation-duration: 0.5s;
    /* animation-delay: 9s; */
    /* animation-iteration-count: infinite; */

}

.s2{

    width: 50%;
    margin: auto;
    animation-delay: 1.5s;
    animation-name: social-rot;
    animation-duration: 0.5s;
    /* animation-delay: 9.5s; */
    /* animation-iteration-count: infinite; */
}

.s3{

    width: 50%;
    /* background-color: white; */
    position: relative;
    /* right: 1%; */
    margin: auto;
    animation-delay: 2s;
    animation-name: social-rot;
    animation-duration: 0.5s;
    /* animation-delay: 10s; */
    /* animation-iteration-count: infinite; */
    
}

.s4{

    width: 50%;
    margin: auto;  
    animation-delay: 2.5s;
    animation-name: social-rot;
    animation-duration: 0.5s;
    /* animation-delay: 10.5s; */
    /* animation-iteration-count: infinite; */
    
}

.s5{

    width: 50%;
    margin: auto;  
    animation-delay: 3s;
    animation-name: social-rot;
    animation-duration: 0.5s;
    /* animation-delay: 10.5s; */
    /* animation-iteration-count: infinite; */
    
}

@keyframes social-rot {

    /* 0%   {width: 50%;}
    50% {width: 60%;}
    100%  {width: 50%;} */
    0%   {width: 50%; transform: rotate(0deg);}
    25%   {transform: rotate(30deg);}
    50% {width: 60%; }
    75%   {transform: rotate(-30deg);}
    100%  {width: 50%; transform: rotate(0deg);}
    
  }

.social_container{

    padding-top: 10px;

    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
    justify-content: space-between;
    width: 75%;
    margin: auto;



}

.social_icon:hover{

    /* position: relative; */
    transition: 0.5s;
    width: 60%;

}

.disapearing{

    width: 35px; 
    height: auto;
    /* background-image: url("https://cdn-icons-png.flaticon.com/512/709/709612.png"); */
    opacity: 1;
    /* background-color: red; */
    animation-name: anim_eye;
    animation-duration: 4s;
    /* animation-name: anim_eye2;
    animation-duration: 2s; */
    animation-iteration-count: infinite;

}



  .edu_card:hover{

    transition: 0.5s;
    box-shadow: -4px -4px 5px rgb(175, 174, 171);

  }

  
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    /* position: fixed;
    bottom: 0; */
    width: 100%;          /* Footer height */         /* Footer height */
}


  @keyframes anim_eye {
    /* from {background-image: url("https://cdn-icons-png.flaticon.com/512/709/709612.png");} */
    /* from {opacity: 1;} */
/*     
    to {background-image: url("https://cdn-icons-png.flaticon.com/512/8901/8901348.png");} */
    /* to {opacity: 0;} */

    0%   {opacity: 1;}
    50%  {opacity: 0;}
    100%  {opacity: 1;}
    
  }




.friendly_modal{

    background-color: '#000000';
    color: 'white';
    animation-name: friendly_modal;
    animation-duration: 1s;
    top: 0px;

}

@keyframes friendly_modal {

    0%   {top: 100px; opacity: 0;}
    100%  {top: 0px; opacity: 1;}
    
  }
